//Permet au image d'être builder dans dist/assets/images
//Elles doivent être appelé au moins une fois pour que webpack les build
import previewImage from '../src/images/preview.jpg';
import favicon16x16 from '../src/images/favicon16x16.png';
import favicon32x32 from '../src/images/favicon32x32.png';
import favicon48x48 from '../src/images/favicon48x48.png';
import favicon64x64 from '../src/images/favicon64x64.png';
import favicon128x128 from '../src/images/favicon128x128.png';
import favicon256x256 from '../src/images/favicon256x256.png';

import './loadingPage.css'
import './style.css'
import './menu.css'
import './about.css'
import './corp.css'
import './career.css'
import './skills.css'
import './projects.css'
import './follow.css'

import './menuSwitch.js'
import './menu_burger.js'
import './videos.js'
import './intersectionObserverAbout.js'
import './intersectionObserverCareer.js'

import * as THREE from 'three'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

// Créez un nouveau gestionnaire de chargement
const manager = new THREE.LoadingManager();
let pageLoad = false;

// Optionnel : ajoutez une fonction à exécuter lorsque le chargement d'un élément commence
manager.onStart = function (url, itemsLoaded, itemsTotal) {
};

// Optionnel : ajoutez une fonction à exécuter pendant le chargement
manager.onProgress = function (url, itemsLoaded, itemsTotal) {
    document.getElementById("progressBar").style.width = (itemsLoaded / itemsTotal) * 100 + '%';
};

// Ajoutez une fonction à exécuter lorsque le chargement d'un élément est terminé
manager.onLoad = function () {
    const loadingPage = document.getElementById("loading");
    const site = document.getElementById("site");
    setTimeout(function () {
        loadingPage.className += " hidden";
        site.classList.remove("siteHidden");
    }, 1000);

    setTimeout(function () {
        pageLoad = true;
    }, 1000);
};

// Ajoutez une fonction à exécuter s'il y a une erreur lors du chargement
manager.onError = function (url) {
    console.log('Il y a eu une erreur lors du chargement de ' + url);
};

/**
 * Loaders
 */
const gltfLoader = new GLTFLoader(manager)

// Canvas
const canvasIntro = document.querySelector('canvas.webglHome')
const canvasAbout = document.querySelector('canvas.webglAbout')
const canvasTypping = document.querySelector('canvas.webglTypping')
const canvasPipeline = document.querySelector('canvas.webglPipeline')
const canvasIacCloud = document.querySelector('canvas.webglIacCloud')
const canvasGithub = document.querySelector('canvas.webglGithub')
const canvasLinkedin = document.querySelector('canvas.webglLinkedin')

// Scene
const sceneHome = new THREE.Scene()
const sceneAbout = new THREE.Scene()
const sceneTypping = new THREE.Scene()
const scenePipeline = new THREE.Scene()
const sceneIacCloud = new THREE.Scene()
const sceneGithub = new THREE.Scene()
const sceneLinkedin = new THREE.Scene()

// Model
let models = [];

/**
 * Update all materials
 */
const updateAllMaterials = () => {
    sceneHome.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.castShadow = true
            child.receiveShadow = true
        }
    })

    sceneAbout.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.castShadow = true
            child.receiveShadow = true
        }
    })

    sceneTypping.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.castShadow = true
            child.receiveShadow = true
        }
    })

    scenePipeline.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.castShadow = true
            child.receiveShadow = true
        }
    })

    sceneIacCloud.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.castShadow = true
            child.receiveShadow = true
        }
    })

    sceneGithub.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.castShadow = true
            child.receiveShadow = true
        }
    })

    sceneLinkedin.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.castShadow = true
            child.receiveShadow = true
        }
    })
}


/**
 * Models
 */
let globalMixerHome;
let globalMixerAbout;
let globalMixerTypping;
let globalMixerPipeline;
let globalMixerIacCloud;
let globalMixerGithub;
let globalMixerLinkedin;

function initializeAnimations(gltf, animationNamesLoopOnce, animationNamesLoopRepeat, isLoop) {
    if (gltf) {
        const mixer = new THREE.AnimationMixer(gltf.scene);

        animationNamesLoopRepeat.forEach((animationName) => {
            const clip = gltf.animations.find((anim) => anim.name === animationName);
            if (clip !== undefined) {
                const action = mixer.clipAction(clip);
                action.loop = THREE.LoopRepeat;
                action.timeScale = 1;
                action.play();
            } else {
                console.log(`Animation "${animationName}" not found`);
            }
        });

        animationNamesLoopOnce.forEach((animationName) => {
            const clip = gltf.animations.find((anim) => anim.name === animationName);
            if (clip !== undefined) {
                const action = mixer.clipAction(clip);
                action.loop = THREE.LoopOnce;
                action.clampWhenFinished = true; // Le modèle reste à l'état de la dernière frame de l'animation
                action.timeScale = 1;
                action.play();
            } else {
                console.log(`Animation "${animationName}" not found`);
            }
        });

        return mixer;
    }

    return null;
}

gltfLoader.load(
    "/models/scene_intro.glb", (gltf) => {
        const model = gltf.scene;
        model.subname = "intro"
        model.scale.set(1.9, 1.9, 1.9);
        models.push(model);

        // Calculate the center of the model
        const box = new THREE.Box3().setFromObject(model);

        sceneHome.add(model);
        updateAllMaterials();

        //Permet de mettre le model au sol
        const modelHeight = box.max.y - box.min.y;
        model.position.y = 2.5;

        // Initialisez l'animation après avoir chargé le modèle
        // Initialisez les animations pour le modèle
        const animationNamesLoopRepeat = [];

        const animationNamesLoopOnce = [
            "ArmatureAction",
            "ArmatureAction.001",
            "ArmatureAction.003",
            "ArmatureAction.005",
            "ArmatureAction.007",
            "ArmatureAction.008",
            "ArmatureAction.009",
            "ArmatureAction.010",
            "ArmatureAction.011",
            "ArmatureAction.012",
            "ArmatureAction.013",
            "ArmatureAction.014"];

        globalMixerHome = initializeAnimations(gltf, animationNamesLoopOnce, animationNamesLoopRepeat, false);
    }
);

gltfLoader.load(
    "/models/me_idle.glb", (gltf) => {
        const model = gltf.scene;
        model.subname = "meIdle"
        model.scale.set(2.2, 2.2, 2.2);
        models.push(model);

        sceneAbout.add(model);
        updateAllMaterials();

        const animationNamesLoopRepeat = ["idle"];
        const animationNamesLoopOnce = [];

        globalMixerAbout = initializeAnimations(gltf, animationNamesLoopOnce, animationNamesLoopRepeat, false);
    }
);

gltfLoader.load(
    "/models/me_typping.glb", (gltf) => {
        const model = gltf.scene;
        model.subname = "meTypping"
        model.scale.set(2.8, 2.8, 2.8);
        models.push(model);

        sceneTypping.add(model);
        updateAllMaterials();

        //Permet de mettre le model au sol
        model.position.y = 1;

        const animationNamesLoopRepeat = ["typping"];
        const animationNamesLoopOnce = [];

        globalMixerTypping = initializeAnimations(gltf, animationNamesLoopOnce, animationNamesLoopRepeat, false);
    }
);

gltfLoader.load(
    "/models/pipeline.glb", (gltf) => {
        const model = gltf.scene;
        model.subname = "pipeline"
        model.scale.set(3.5, 3.5, 3.5);
        models.push(model);

        scenePipeline.add(model);
        updateAllMaterials();

        //Permet de mettre le model au sol
        const box = new THREE.Box3().setFromObject(model);
        const modelHeight = box.max.y - box.min.y;
        model.position.y = -4.5 + modelHeight / 2;

        const animationNamesLoopRepeat = ["squash", "turn", "test", "deploy"];
        const animationNamesLoopOnce = [];

        globalMixerPipeline = initializeAnimations(gltf, animationNamesLoopOnce, animationNamesLoopRepeat, false);
    }
);

gltfLoader.load(
    "/models/iac_cloud.glb", (gltf) => {
        const model = gltf.scene;
        model.subname = "iac_cloud"
        model.scale.set(2.7, 2.7, 2.7);
        models.push(model);

        sceneIacCloud.add(model);
        updateAllMaterials();

        //Permet de mettre le model au sol
        model.position.y = 0.5;

        const animationNamesLoopRepeat = ["action"];
        const animationNamesLoopOnce = [];

        globalMixerIacCloud = initializeAnimations(gltf, animationNamesLoopOnce, animationNamesLoopRepeat, false);
    }
);

gltfLoader.load(
    "/models/github_icon.glb", (gltf) => {
        const model = gltf.scene;
        model.subname = "github"
        model.scale.set(4, 4, 4);
        models.push(model);

        sceneGithub.add(model);
        updateAllMaterials();

        //Permet de mettre le model au sol
        model.position.y = 0.5;

        const animationNamesLoopRepeat = ["turn"];
        const animationNamesLoopOnce = [];

        globalMixerGithub = initializeAnimations(gltf, animationNamesLoopOnce, animationNamesLoopRepeat, false);
    }
);

gltfLoader.load(
    "/models/linkedin_icon.glb", (gltf) => {
        const model = gltf.scene;
        model.subname = "linkedin"
        model.scale.set(4, 4, 4);
        models.push(model);

        sceneLinkedin.add(model);
        updateAllMaterials();

        //Permet de mettre le model au sol
        model.position.y = 0.5;

        const animationNamesLoopRepeat = ["turn"];
        const animationNamesLoopOnce = [];

        globalMixerLinkedin = initializeAnimations(gltf, animationNamesLoopOnce, animationNamesLoopRepeat, false);
    }
);

/**
 * Lights
 */

//---------------------------------------------------------------
// ECLAIRAGE SCENE HOME
//---------------------------------------------------------------

const ambientLight = new THREE.AmbientLight(0x404040, 1);
sceneHome.add(ambientLight);

const directionalLight = new THREE.DirectionalLight('#ffffff', 20)
directionalLight.castShadow = true
directionalLight.intensity = 2
directionalLight.shadow.camera.far = 15
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.normalBias = 0.05
directionalLight.shadow.radius = 10
directionalLight.position.set(-20, 20, -7)
sceneHome.add(directionalLight)

const directionalLight2 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight2.castShadow = true
directionalLight2.intensity = 5
directionalLight2.shadow.camera.far = 15
directionalLight2.shadow.mapSize.set(1024, 1024)
directionalLight2.shadow.normalBias = 0.05
directionalLight2.shadow.radius = 10
directionalLight2.position.set(10, 4, 6)
sceneHome.add(directionalLight2)

//---------------------------------------------------------------
// ECLAIRAGE SCENE ABOUT
//---------------------------------------------------------------

const ambientLight2 = new THREE.AmbientLight(0x404040, 1);
sceneAbout.add(ambientLight2);

const directionalLight3 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight3.castShadow = true
directionalLight3.intensity = 2
directionalLight3.shadow.camera.far = 15
directionalLight3.shadow.mapSize.set(1024, 1024)
directionalLight3.shadow.normalBias = 0.05
directionalLight3.shadow.radius = 10
directionalLight3.position.set(-7, 20, 3)
sceneAbout.add(directionalLight3)

const directionalLight4 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight4.castShadow = true
directionalLight4.intensity = 1
directionalLight4.shadow.camera.far = 15
directionalLight4.shadow.mapSize.set(1024, 1024)
directionalLight4.shadow.normalBias = 0.05
directionalLight4.shadow.radius = 10
directionalLight4.position.set(20, 3, 2)
sceneAbout.add(directionalLight4)

//---------------------------------------------------------------
// ECLAIRAGE SCENE TYPPING
//---------------------------------------------------------------

const ambientLight3 = new THREE.AmbientLight(0x404040, 1);
sceneTypping.add(ambientLight3);

const directionalLight5 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight5.castShadow = true
directionalLight5.intensity = 1.5
directionalLight5.shadow.camera.far = 15
directionalLight5.shadow.mapSize.set(1024, 1024)
directionalLight5.shadow.normalBias = 0.05
directionalLight5.shadow.radius = 10
directionalLight5.position.set(5, 20, 3)
sceneTypping.add(directionalLight5)

const directionalLight6 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight6.castShadow = true
directionalLight6.intensity = 1.5
directionalLight6.shadow.camera.far = 15
directionalLight6.shadow.mapSize.set(1024, 1024)
directionalLight6.shadow.normalBias = 0.05
directionalLight6.shadow.radius = 10
directionalLight6.position.set(9, 6, 10)
sceneTypping.add(directionalLight6)

//---------------------------------------------------------------
// ECLAIRAGE SCENE PIPELINE
//---------------------------------------------------------------

const ambientLight4 = new THREE.AmbientLight(0x404040, 1);
scenePipeline.add(ambientLight4);

const directionalLight7 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight7.castShadow = true
directionalLight7.intensity = 4
directionalLight7.shadow.camera.far = 15
directionalLight7.shadow.mapSize.set(1024, 1024)
directionalLight7.shadow.normalBias = 0.05
directionalLight7.shadow.radius = 10
directionalLight7.position.set(-20, 2, -20)
scenePipeline.add(directionalLight7)

const directionalLight8 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight8.castShadow = true
directionalLight8.intensity = 2
directionalLight8.shadow.camera.far = 15
directionalLight8.shadow.mapSize.set(1024, 1024)
directionalLight8.shadow.normalBias = 0.05
directionalLight8.shadow.radius = 10
directionalLight8.position.set(-7, 7, 20)
scenePipeline.add(directionalLight8)

//---------------------------------------------------------------
// ECLAIRAGE SCENE IAC CLOUD
//---------------------------------------------------------------

const ambientLight5 = new THREE.AmbientLight(0x404040, 1);
sceneIacCloud.add(ambientLight5);

const directionalLight9 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight9.castShadow = true
directionalLight9.intensity = 1
directionalLight9.shadow.camera.far = 15
directionalLight9.shadow.mapSize.set(1024, 1024)
directionalLight9.shadow.normalBias = 0.05
directionalLight9.shadow.radius = 10
directionalLight9.position.set(12, 7, 10)
sceneIacCloud.add(directionalLight9)

const directionalLight10 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight10.castShadow = true
directionalLight10.intensity = 2
directionalLight10.shadow.camera.far = 15
directionalLight10.shadow.mapSize.set(1024, 1024)
directionalLight10.shadow.normalBias = 0.05
directionalLight10.shadow.radius = 10
directionalLight10.position.set(-20, 10, 20)
sceneIacCloud.add(directionalLight10)

//---------------------------------------------------------------
// ECLAIRAGE SCENE GITHUB
//---------------------------------------------------------------

const ambientLight6 = new THREE.AmbientLight(0x404040, 1);
sceneGithub.add(ambientLight6);

const directionalLight11 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight11.castShadow = true
directionalLight11.intensity = 7
directionalLight11.shadow.camera.far = 15
directionalLight11.shadow.mapSize.set(1024, 1024)
directionalLight11.shadow.normalBias = 0.05
directionalLight11.shadow.radius = 10
directionalLight11.position.set(20, 20, 10)
sceneGithub.add(directionalLight11)

const directionalLight12 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight12.castShadow = true
directionalLight12.intensity = 7
directionalLight12.shadow.camera.far = 15
directionalLight12.shadow.mapSize.set(1024, 1024)
directionalLight12.shadow.normalBias = 0.05
directionalLight12.shadow.radius = 10
directionalLight12.position.set(3, 3, 20)
sceneGithub.add(directionalLight12)

//---------------------------------------------------------------
// ECLAIRAGE SCENE LINKEDIN
//---------------------------------------------------------------

const ambientLight7 = new THREE.AmbientLight(0x404040, 1);
sceneLinkedin.add(ambientLight7);

const directionalLight13 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight13.castShadow = true
directionalLight13.intensity = 7
directionalLight13.shadow.camera.far = 15
directionalLight13.shadow.mapSize.set(1024, 1024)
directionalLight13.shadow.normalBias = 0.05
directionalLight13.shadow.radius = 10
directionalLight13.position.set(20, 20, 10)
sceneLinkedin.add(directionalLight13)

const directionalLight14 = new THREE.DirectionalLight('#ffffff', 20)
directionalLight14.castShadow = true
directionalLight14.intensity = 7
directionalLight14.shadow.camera.far = 15
directionalLight14.shadow.mapSize.set(1024, 1024)
directionalLight14.shadow.normalBias = 0.05
directionalLight14.shadow.radius = 10
directionalLight14.position.set(3, 3, 20)
sceneLinkedin.add(directionalLight14)

/**
 * Sizes
*/
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
    widthMeIdle: canvasAbout.offsetWidth,
    heightMeIdle: canvasAbout.offsetHeight,
    widthMeTypping: canvasTypping.offsetWidth,
    heightMeTypping: canvasTypping.offsetHeight,
    widthPipeline: canvasPipeline.offsetWidth,
    heightPipeline: canvasPipeline.offsetHeight,
    widthIacCloud: canvasIacCloud.offsetWidth,
    heightIacCloud: canvasIacCloud.offsetHeight,
    widthGithub: canvasGithub.offsetWidth,
    heightGithub: canvasGithub.offsetHeight,
    widthLinkedin: canvasLinkedin.offsetWidth,
    heightLinkedin: canvasLinkedin.offsetHeight
}

window.addEventListener('resize', () => {
    resize();
})

function resize() {

    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    sizes.widthMeIdle = canvasAbout.offsetWidth
    sizes.heightMeIdle = canvasAbout.offsetHeight
    sizes.widthMeTypping = canvasTypping.offsetWidth
    sizes.heightMeTypping = canvasTypping.offsetHeight
    sizes.widthPipeline = canvasPipeline.offsetWidth
    sizes.heightPipeline = canvasPipeline.offsetHeight
    sizes.widthIacCloud = canvasIacCloud.offsetWidth
    sizes.heightIacCloud = canvasIacCloud.offsetHeight
    sizes.widthGithub = canvasGithub.offsetWidth
    sizes.heightGithub = canvasGithub.offsetHeight
    sizes.widthLinkedin = canvasLinkedin.offsetWidth
    sizes.heightLinkedin = canvasLinkedin.offsetHeight

    // Update camera
    cameraHome.aspect = sizes.width / sizes.height
    cameraHome.updateProjectionMatrix()

    cameraAbout.aspect = sizes.widthMeIdle / sizes.heightMeIdle
    cameraAbout.updateProjectionMatrix()

    cameraTypping.aspect = sizes.widthMeTypping / sizes.heightMeTypping
    cameraTypping.updateProjectionMatrix()

    cameraPipeline.aspect = sizes.widthPipeline / sizes.heightPipeline
    cameraPipeline.updateProjectionMatrix()

    cameraIacCloud.aspect = sizes.widthIacCloud / sizes.heightIacCloud
    cameraIacCloud.updateProjectionMatrix()

    cameraGithub.aspect = sizes.widthGithub / sizes.heightGithub
    cameraGithub.updateProjectionMatrix()

    cameraLinkedin.aspect = sizes.widthLinkedin / sizes.heightLinkedin
    cameraLinkedin.updateProjectionMatrix()

    // Update renderer
    rendererHome.setSize(sizes.width, sizes.height)
    rendererHome.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    rendererAbout.setSize(sizes.widthMeIdle, sizes.heightMeIdle)
    rendererAbout.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    rendererTypping.setSize(sizes.widthMeTypping, sizes.heightMeTypping)
    rendererTypping.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    rendererPipeline.setSize(sizes.widthPipeline, sizes.heightPipeline)
    rendererPipeline.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    rendererIacCloud.setSize(sizes.widthIacCloud, sizes.heightIacCloud)
    rendererIacCloud.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    rendererGithub.setSize(sizes.widthGithub, sizes.heightGithub)
    rendererGithub.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    rendererLinkedin.setSize(sizes.widthLinkedin, sizes.heightLinkedin)
    rendererLinkedin.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Update model positions
    models.forEach((model) => {  // Parcourir tous les modèles
        if (model.subname == "intro") {
            if (window.innerWidth < 1500 && window.innerWidth >= 1200) {
                model.scale.set(1.5, 1.5, 1.5);
            } else if (window.innerWidth < 1200 && window.innerWidth >= 950) {
                model.scale.set(1.2, 1.2, 1.2);
            } else if (window.innerWidth < 950 && window.innerWidth >= 750) {
                model.scale.set(1, 1, 1);
            } else if (window.innerWidth < 750) {
                model.scale.set(0.7, 0.7, 0.7);
            }
            else {
                model.scale.set(1.9, 1.9, 1.9);
            }
        }

        if (model) {  // Assurez-vous que le modèle a été chargé avant d'essayer d'ajuster sa position
            const box = new THREE.Box3().setFromObject(model);
            const center = box.getCenter(new THREE.Vector3());
            model.position.x = -center.x;
            model.position.z = -center.z;
        }
    });
}

/**
 * Renderer
 */
const rendererHome = new THREE.WebGLRenderer({
    canvas: canvasIntro,
    antialias: true
})

rendererHome.physicallyCorrectLights = true
rendererHome.outputColorSpace = THREE.SRGBColorSpace
rendererHome.toneMapping = THREE.LinearToneMapping
rendererHome.toneMappingExposure = 2
rendererHome.shadowMap.enabled = true
rendererHome.shadowMap.type = THREE.PCFSoftShadowMap
rendererHome.setSize(sizes.width, sizes.height)
rendererHome.setPixelRatio(Math.min(window.devicePixelRatio, 2))

const rendererAbout = new THREE.WebGLRenderer({
    canvas: canvasAbout,
    antialias: true
})

rendererAbout.physicallyCorrectLights = true
rendererAbout.outputColorSpace = THREE.SRGBColorSpace
rendererAbout.toneMapping = THREE.LinearToneMapping
rendererAbout.toneMappingExposure = 2.2
rendererAbout.shadowMap.enabled = true
rendererAbout.shadowMap.type = THREE.PCFSoftShadowMap
rendererAbout.setSize(sizes.heightMeIdle * 1.5, sizes.widthMeIdle * 1.5)
rendererAbout.setPixelRatio(Math.min(window.devicePixelRatio, 2))
rendererAbout.setClearColor(0xffffff, 1);

const rendererTypping = new THREE.WebGLRenderer({
    canvas: canvasTypping,
    antialias: true
})

rendererTypping.physicallyCorrectLights = true
rendererTypping.outputColorSpace = THREE.SRGBColorSpace
rendererTypping.toneMapping = THREE.LinearToneMapping
rendererTypping.toneMappingExposure = 2.2
rendererTypping.shadowMap.enabled = true
rendererTypping.shadowMap.type = THREE.PCFSoftShadowMap
rendererTypping.setSize(sizes.widthMeTypping, sizes.heightMeTypping)
rendererTypping.setPixelRatio(Math.min(window.devicePixelRatio, 2))
rendererTypping.setClearColor(0xffffff, 1);

const rendererPipeline = new THREE.WebGLRenderer({
    canvas: canvasPipeline,
    antialias: true
})

rendererPipeline.physicallyCorrectLights = true
rendererPipeline.outputColorSpace = THREE.SRGBColorSpace
rendererPipeline.toneMapping = THREE.LinearToneMapping
rendererPipeline.toneMappingExposure = 2.2
rendererPipeline.shadowMap.enabled = true
rendererPipeline.shadowMap.type = THREE.PCFSoftShadowMap
rendererPipeline.setSize(sizes.widthPipeline, sizes.heightPipeline)
rendererPipeline.setPixelRatio(Math.min(window.devicePixelRatio, 2))
rendererPipeline.setClearColor(0xffffff, 1);

const rendererIacCloud = new THREE.WebGLRenderer({
    canvas: canvasIacCloud,
    antialias: true
})

rendererIacCloud.physicallyCorrectLights = true
rendererIacCloud.outputColorSpace = THREE.SRGBColorSpace
rendererIacCloud.toneMapping = THREE.LinearToneMapping
rendererIacCloud.toneMappingExposure = 2.2
rendererIacCloud.shadowMap.enabled = true
rendererIacCloud.shadowMap.type = THREE.PCFSoftShadowMap
rendererIacCloud.setSize(sizes.widthIacCloud, sizes.heightIacCloud)
rendererIacCloud.setPixelRatio(Math.min(window.devicePixelRatio, 2))
rendererIacCloud.setClearColor(0xffffff, 1);

const rendererGithub = new THREE.WebGLRenderer({
    canvas: canvasGithub,
    antialias: true
})

rendererGithub.physicallyCorrectLights = true
rendererGithub.outputColorSpace = THREE.SRGBColorSpace
rendererGithub.toneMapping = THREE.LinearToneMapping
rendererGithub.toneMappingExposure = 2.2
rendererGithub.shadowMap.enabled = true
rendererGithub.shadowMap.type = THREE.PCFSoftShadowMap
rendererGithub.setSize(sizes.widthGithub, sizes.heightGithub)
rendererGithub.setPixelRatio(Math.min(window.devicePixelRatio, 2))
rendererGithub.setClearColor(0xffffff, 1);

const rendererLinkedin = new THREE.WebGLRenderer({
    canvas: canvasLinkedin,
    antialias: true
})

rendererLinkedin.physicallyCorrectLights = true
rendererLinkedin.outputColorSpace = THREE.SRGBColorSpace
rendererLinkedin.toneMapping = THREE.LinearToneMapping
rendererLinkedin.toneMappingExposure = 2.2
rendererLinkedin.shadowMap.enabled = true
rendererLinkedin.shadowMap.type = THREE.PCFSoftShadowMap
rendererLinkedin.setSize(sizes.widthLinkedin, sizes.heightLinkedin)
rendererLinkedin.setPixelRatio(Math.min(window.devicePixelRatio, 2))
rendererLinkedin.setClearColor(0xffffff, 1);

/**
 * Camera
 */

const cameraHome = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
cameraHome.position.set(0, 3, 5)
cameraHome.position.z = 10;

const cameraAbout = new THREE.PerspectiveCamera(45, sizes.widthMeIdle / sizes.heightMeIdle, 0.1, 100)
cameraAbout.position.set(0, 3, 5)
cameraAbout.position.z = 10;

const cameraTypping = new THREE.PerspectiveCamera(45, sizes.widthMeTypping / sizes.heightMeTypping, 0.1, 100)
cameraTypping.position.set(0, 3, 5)
cameraTypping.position.z = 10;


const cameraPipeline = new THREE.PerspectiveCamera(45, sizes.widthPipeline / sizes.heightPipeline, 0.1, 100)
cameraPipeline.position.set(0, 3, 5)
cameraPipeline.position.z = 10;


const cameraIacCloud = new THREE.PerspectiveCamera(45, sizes.widthIacCloud / sizes.heightIacCloud, 0.1, 100)
cameraIacCloud.position.set(0, 3, 5)
cameraIacCloud.position.z = 10;

const cameraGithub = new THREE.PerspectiveCamera(45, sizes.widthGithub / sizes.heightGithub, 0.1, 100)
cameraGithub.position.set(0, 3, 5)
cameraGithub.position.z = 10;

const cameraLinkedin = new THREE.PerspectiveCamera(45, sizes.widthLinkedin / sizes.heightLinkedin, 0.1, 100)
cameraLinkedin.position.set(0, 3, 5)
cameraLinkedin.position.z = 10;


//camera3.lookAt(scene3.position);

sceneHome.add(cameraHome)
sceneAbout.add(cameraAbout)
sceneTypping.add(cameraTypping)
scenePipeline.add(cameraPipeline)
sceneIacCloud.add(cameraIacCloud)
sceneGithub.add(cameraGithub)
sceneLinkedin.add(cameraLinkedin)

/**
 * Animate
*/
const clock = new THREE.Clock()

let modelHomeLoad = false;

const tick = () => {

    const deltaTime = clock.getDelta()

    // Update controls
    //controls.update()

    // Animations
    // Mettez à jour les états des animations si l'animation est chargée
    if (globalMixerHome && pageLoad) {
        globalMixerHome.update(deltaTime);
        if (!modelHomeLoad) {
            resize();
            modelHomeLoad = true;
        }
    }

    if (globalMixerAbout) {
        globalMixerAbout.update(deltaTime);
    }

    if (globalMixerTypping) {
        globalMixerTypping.update(deltaTime);
    }

    if (globalMixerPipeline) {
        globalMixerPipeline.update(deltaTime);
    }

    if (globalMixerIacCloud) {
        globalMixerIacCloud.update(deltaTime);
    }

    if (globalMixerGithub) {
        globalMixerGithub.update(deltaTime);
    }

    if (globalMixerLinkedin) {
        globalMixerLinkedin.update(deltaTime);
    }

    // Render
    rendererHome.render(sceneHome, cameraHome)
    rendererAbout.render(sceneAbout, cameraAbout)
    rendererTypping.render(sceneTypping, cameraTypping)
    rendererPipeline.render(scenePipeline, cameraPipeline)
    rendererIacCloud.render(sceneIacCloud, cameraIacCloud)
    rendererGithub.render(sceneGithub, cameraGithub)
    rendererLinkedin.render(sceneLinkedin, cameraLinkedin)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

resize();

tick()







/*

const gui = new dat.GUI();

const directionalLightFolder = gui.addFolder('Directional Light');

*/
/*
// Contrôle pour la propriété castShadow
directionalLightFolder.add(directionalLight5, 'castShadow').name('Cast Shadow');
directionalLightFolder.add(directionalLight6, 'castShadow').name('Cast Shadow');
*/

/*

// Contrôle pour la propriété intensity
directionalLightFolder.add(directionalLight13, 'intensity', 0, 10, 0.1).name('Intensity');
directionalLightFolder.add(directionalLight14, 'intensity', 0, 10, 0.1).name('Intensity');

// Contrôles pour la position de la lumière directionnelle
directionalLightFolder.add(directionalLight13.position, 'x', -20, 20, 0.1).name('X Position');
directionalLightFolder.add(directionalLight13.position, 'y', -20, 20, 0.1).name('Y Position');
directionalLightFolder.add(directionalLight13.position, 'z', -20, 20, 0.1).name('Z Position');
directionalLightFolder.add(directionalLight14.position, 'x', -20, 20, 0.1).name('X Position');
directionalLightFolder.add(directionalLight14.position, 'y', -20, 20, 0.1).name('Y Position');
directionalLightFolder.add(directionalLight14.position, 'z', -20, 20, 0.1).name('Z Position');
*/

/*
// Contrôles pour les propriétés d'ombre
const shadowFolder = directionalLightFolder.addFolder('Shadow');
shadowFolder.add(directionalLight5.shadow.camera, 'far', 0, 50, 0.1).name('Shadow Camera Far');
shadowFolder.add(directionalLight5.shadow, 'normalBias', 0, 1, 0.01).name('Shadow Normal Bias');
shadowFolder.add(directionalLight5.shadow, 'radius', 0, 20, 0.1).name('Shadow Radius');
shadowFolder.add(directionalLight5.shadow, 'bias', -0.1, 0.1, 0.001).name('Shadow Bias');
shadowFolder.add(directionalLight6.shadow.camera, 'far', 0, 50, 0.1).name('Shadow Camera Far');
shadowFolder.add(directionalLight6.shadow, 'normalBias', 0, 1, 0.01).name('Shadow Normal Bias');
shadowFolder.add(directionalLight6.shadow, 'radius', 0, 20, 0.1).name('Shadow Radius');
shadowFolder.add(directionalLight6.shadow, 'bias', -0.1, 0.1, 0.001).name('Shadow Bias');

// Contrôles pour la taille de la carte d'ombre
const shadowMapSizeFolder = shadowFolder.addFolder('Shadow Map Size');
shadowMapSizeFolder.add(directionalLight5.shadow.mapSize, 'width', 256, 4096, 1).name('Width').step(1);
shadowMapSizeFolder.add(directionalLight6.shadow.mapSize, 'height', 256, 4096, 1).name('Height').step(1);

directionalLightFolder.open();

// Contrôles pour les propriétés d'ombre
shadowFolder.add(directionalLight5.shadow, 'bias', -0.1, 0.1, 0.001).name('Shadow Bias');
shadowFolder.add(directionalLight5.shadow, 'normalBias', 0, 1, 0.01).name('Shadow Normal Bias');

// Ajoutez les contrôles pour la deuxième lumière directionnelle
shadowFolder.add(directionalLight6.shadow, 'bias', -0.1, 0.1, 0.001).name('Shadow Bias 2');
shadowFolder.add(directionalLight6.shadow, 'normalBias', 0, 1, 0.01).name('Shadow Normal Bias 2');



/*
// Ajouter un dossier pour le plan
const planeFolder = gui.addFolder('Plane');

// Contrôles pour la rotation du plan

planeFolder.add(plane3.rotation, 'x', -Math.PI, Math.PI, 0.01).name('X Rotation');
planeFolder.add(plane3.rotation, 'y', -Math.PI, Math.PI, 0.01).name('Y Rotation');
planeFolder.add(plane3.rotation, 'z', -Math.PI, Math.PI, 0.01).name('Z Rotation');

// Contrôle pour la hauteur du plan
planeFolder.add(plane3.position, 'y', -50, 50, 0.1).name('Height');

// Ouvrir le dossier par défaut
planeFolder.open();
*/

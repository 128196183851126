function checkOrientation() {
    var videoPortrait = document.getElementById("videoPortrait");
    var videoPaysage = document.getElementById("videoPaysage");

    const isPortrait = window.matchMedia("(orientation: portrait)").matches;

    if (isPortrait) {  
        videoPortrait.style.display = "block";
        videoPaysage.style.display = "none";
    } else {  
        videoPortrait.style.display = "none";
        videoPaysage.style.display = "block";
    }
}

// Check on initial load
checkOrientation();

// Update on orientation change
window.matchMedia("(orientation: portrait)").addListener(checkOrientation);
window.matchMedia("(orientation: landscape)").addListener(checkOrientation);

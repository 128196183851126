// Création d'un nouvel IntersectionObserver qui écoute si un élément est visible dans le viewport
let observer = new IntersectionObserver((observables) => {

    // On parcourt tous les éléments observés
    observables.forEach((observable) => {
        // Si l'élément est à plus de 25% dans le viewport
        if (observable.intersectionRatio > 0.25) {
            // On retire la classe "not-visible"
            observable.target.classList.remove("not-visible");
        } else {
            // Sinon, on ajoute la classe "not-visible"
            observable.target.classList.add("not-visible");
        }
    });

}, {
    // L'option threshold définit le pourcentage de visibilité à partir duquel l'IntersectionObserver commence à observer l'élément
    threshold: [0.25]
});

// Sélection de tous les éléments avec la classe '.container'
let items = document.querySelectorAll('.container');

// Pour chaque élément
items.forEach((item) => {
    // On ajoute la classe "not-visible" pour initialement cacher l'élément
    item.classList.add("not-visible");

    // On demande à l'IntersectionObserver d'observer cet élément
    observer.observe(item);
});

// Obtenir la section carrière et la légende du DOM
const career = document.querySelector('.career');
const legend = document.querySelector('.legend');

// Enregistrer la position top initiale de la légende et la position left initiale de la légende
// La position top initiale prend en compte la position de la section carrière et la position de la légende à l'intérieur de la section carrière.
const initialTopCareer = legend.offsetTop;
const initialLeftCareer = legend.offsetLeft;
const initialTop = legend.getBoundingClientRect().top + window.pageYOffset;

// Ajouter un écouteur d'événement pour le scroll
window.addEventListener('scroll', function() {
    // Obtenir les dimensions et la position de la section carrière
    const careerRect = career.getBoundingClientRect();
    // Calculer le bas de la section carrière par rapport à la page
    const careerBottom = careerRect.bottom + window.pageYOffset;

    if(window.pageYOffset <= career.offsetTop) {
        legend.style.position = 'absolute';
        legend.style.top = '75px';
        legend.style.left = '0px';
    }
    // Si le scroll est entre le début de la section carrière et le bas de la légende, fixer la position de la légende à 75px de top et 25px de left
    else if (window.pageYOffset >= initialTopCareer && window.pageYOffset < careerBottom - legend.offsetHeight) {
        legend.style.position = 'fixed';
        legend.style.top = '75px';
        legend.style.left = '0px';
    }
    // Si le scroll est en dessous de la section carrière, positionner la légende à sa position d'origine dans la section carrière
    else if (window.pageYOffset >= careerBottom - legend.offsetHeight) {
        legend.style.position = 'absolute';
        legend.style.top = (careerRect.height - legend.offsetHeight) + 'px';
        legend.style.left = initialLeftCareer + 'px';
    } 
    // Si le scroll est au dessus de la section carrière, positionner la légende à sa position d'origine sur la page
    else {
        legend.style.position = 'absolute';
        legend.style.top = legend.offsetTop + 'px';
        legend.style.left = initialLeftCareer + 'px';
    }
});


// Sélectionner l'élément à animer
const div = document.querySelector('.text');

// Créer un nouvel Intersection Observer avec une marge plus importante
const animationObserver = new IntersectionObserver(
  (entries) => {
    // Pour chaque entry...
    entries.forEach(entry => {
      // Si l'élément est à plus de 0% dans le viewport...
      if (entry.isIntersecting) {
        // Ajouter la classe "show" et supprimer la classe "hide"
        div.classList.add("show");
        div.classList.remove("hide");
      } else {
        // Ajouter la classe "hide" et supprimer la classe "show"
        div.classList.add("hide");
        div.classList.remove("show");
      }
    });
  },
  {
    // L'option threshold définit le pourcentage de visibilité à partir duquel l'IntersectionObserver commence à observer l'élément
    threshold: [0, 0.1, 1.0],
    rootMargin: '-200px 0px -200px 0px' // ajuster ces valeurs selon votre besoin
  }
);

// Commencer à observer l'élément
animationObserver.observe(div);
